import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "stepper"
    }}>{`Stepper`}</h1>
    <p>{`Provide an initial value of type `}<inlineCode parentName="p">{`Int`}</inlineCode>{`. Specify the min/max range with `}<inlineCode parentName="p">{`in`}</inlineCode>{`.`}</p>
    <ContentBlock imgName="Form-stepper.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    struct Stepper<Label> where Label : View
*/
struct FormStepper: View {
    @State private var value: Int = 0\n
    var body: some View {
        Form {
            Stepper("Choose a value", value: $value, in: 0...100)\n
            Stepper(value: $value, in: 0...100, label: {
                HStack {
                    Image(systemName: "faceid")
                    Text("With Custom Label")
                }
            })\n
            Stepper("With Custom Callbacks", onIncrement: {
                self.value += 1
                print("Added 1 to value")
            }, onDecrement: {
                self.value -= 1
                print("Subtracted 1 to value")
            })
        }
    }
}
    `}
  </code>
    </ContentBlock>
    <h2 {...{
      "id": "plain-text-label"
    }}>{`Plain Text Label`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Stepper("Choose a value", value: $value, in: 0...100
`}</code></pre>
    <h2 {...{
      "id": "custom-label"
    }}>{`Custom Label`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Stepper(value: $value, in: 0...100, label: {
    HStack {
        Image(systemName: "faceid")
        Text("With Custom Label")
    }
})
`}</code></pre>
    <h2 {...{
      "id": "custom-callbacks"
    }}>{`Custom Callbacks`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-clike"
      }}>{`Stepper("With Custom Callbacks", onIncrement: {
    self.value += 1
    print("Added 1 to value")
}, onDecrement: {
    self.value -= 1
    print("Subtracted 1 to value")
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      